import * as React from "react";
import {useEffect} from "react";
import {PageTitles} from "../../locales/pageTitles";
import {SiigoAppIntegration} from "./SiigoAppIntegration";
import SiigoAppIntegrationHeader from "./SiigoAppIntegrationHeader";

export function SiigoAppIntegrationRoot() {
    useEffect(() => {
        document.title = PageTitles.userSiigoAppIntegration;
    }, []);

    return (
        <div className="p-4 account-settings" id="accountRoot">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xl-10 col-xxl-10">
                    <SiigoAppIntegrationHeader/>
                    <SiigoAppIntegration/>
                </div>
            </div>
        </div>
    );
}
