import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {ExportTypes} from "../api/exportTypes";
import {postFile} from "../api/postFile";
import {getHeaders} from "../api/requestHeaders";
import {useAuth} from "../AuthProvider";
import {PageStatus} from "../pages/pageStatus";

export default function useReportExport(
    companyId: string,
    companyName: string,
    reportDate: string) {
    const {getToken} = useAuth();

    const [fileType, setFileType] = useState<ExportTypes>(ExportTypes.CSV);
    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const doExport = async (fileType: ExportTypes) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const data = {
                'company_id': companyId,
                'period_end_date': reportDate,
                'report_type': ''
            };

            // Remove all non-alphanumeric characters.
            const printableCompanyName = companyName.replace(/[^\p{L}\d]/gu, '');
            let fileName: string;
            let url: string;

            if (fileType === ExportTypes.CSV) {
                fileName = `CFOdigital_${printableCompanyName}_${reportDate}.csv`;
                url = ApiEndpoints.exportPerformancePrognosis;
                data['report_type'] = 'csv';

            } else {
                fileName = `CFOdigital_${printableCompanyName}_${reportDate}.pdf`;
                url = ApiEndpoints.exportPerformancePrognosis;
                data['report_type'] = 'pdf';
            }

            const uploadFile = await postFile(url, data, headers, signal, fileName);

            if (doExitEarly(uploadFile)) {
                return;
            }

            setStatus(PageStatus.IsReady);
        };

        doExport(fileType).then();

        return () => {
            controller.abort();
        };

    }, [fileType, status, getToken, companyId, companyName, reportDate]);

    const exportReport = (exportType: ExportTypes) => {
        setFileType(exportType);
        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);
    };

    return {
        status, message, validationErrors, exportReport
    };
}