export class IntegrationLoadTemplateParams {
    year: number = 0;
    quarter: number = 0;

    toJson() {
        return {
            "year": this.year,
            "quarter": this.quarter
        };
    }
}
