import {UserSetting} from "./userSetting";
import {UserSettingList} from "./userSettingList";

export class UserSettingsUpdateParams {

    settings: UserSettingList = new UserSettingList();

    toJson() {

        const s: any[] = [];

        this.settings.forEach(function (setting: UserSetting) {
            s.push({
                "setting_key": setting.settingKey,
                "setting_value": setting.settingValue
            });
        });

        return {
            "settings": s
        };
    }
}
