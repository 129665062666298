import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {Link} from 'react-router-dom';
import {Company} from "../api/companies/company";
import {EntitlementTypes} from "../api/entitlementTypes";
import {useAuth} from "../AuthProvider";
import {useCompanyList} from "../CompanyProvider";
import {Strings} from "../locales/strings";
import {PageEndpoints} from '../pages/pageEndpoints';
import {getPublicUrl} from "../utils/envUtils";

export default function Menu() {
    const {getEntitlement} = useAuth();
    const {getCompanies, getCurrentCompanyId, setCurrentCompanyId} = useCompanyList();

    const companyList = getCompanies();
    const companyId = getCurrentCompanyId();
    const companyName = companyList.getCompany(companyId)?.companyName || 'No companies yet';

    const authorizedCompanyCount = getEntitlement(EntitlementTypes.COMPANIES_COUNT) || 1;

    const isAddCompanyAuthorized =
        authorizedCompanyCount === -1 ||
        companyList.length < authorizedCompanyCount;

    function handleSelect(event: any) {
        console.log('SELECTED: ' + event.target);
    }

    return (
        <div className="min-vh-100-sm d-flex flex-sm-column flex-row flex-nowrap align-items-center sticky-top">
            <a href="/public" className="d-block link-dark p-3 text-decoration-none logo" title=""
               data-bs-toggle="tooltip"
               data-bs-placement="right" data-bs-original-title="Icon-only">
                <div className="logo d-none d-sm-inline">
                    <picture>
                        <img src={getPublicUrl() + '/images/cfo-digital-logo.png'}
                             alt="FinRatio CFOdigital logo"/>
                    </picture>
                </div>
            </a>

            <div className="ms-auto ms-sm-0 flex-shrink-1 company-name">
                <Dropdown key="companyNameMenu" drop={'down-centered'}>
                    <Dropdown.Toggle id="companyList" variant="white">
                        {companyName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu onSelect={handleSelect} className="w-100">
                        {
                            isAddCompanyAuthorized &&
                            <Dropdown.Item key="addCompany" as={Link} to={PageEndpoints.companyNew}>
                                Add company...
                            </Dropdown.Item>
                        }

                        {
                            !isAddCompanyAuthorized &&
                            <Dropdown.Item key="addCompanyLimit">
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{show: 0, hide: 700}}
                                    overlay={(
                                        <Popover>
                                            <Popover.Body>
                                                {Strings.FEATURE_LIMIT}
                                            </Popover.Body>
                                        </Popover>
                                    )}>
                                    <div className="text-muted">
                                        Add company...
                                    </div>
                                </OverlayTrigger>
                            </Dropdown.Item>
                        }

                        {companyList.length > 1
                            ? <Dropdown.Divider key="companyDivider"/>
                            : null
                        }

                        {companyList.map((item: Company) => (
                            item.companyId === companyId
                                ? null
                                : <Dropdown.Item key={item.companyId} onClick={(function () {
                                    setCurrentCompanyId(item.companyId);
                                    return false;
                                })}>
                                    {item.companyName}
                                </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <hr className="nav-divider d-none d-sm-block"/>

            <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto justify-content-between w-100 px-3 align-items-center">
                {/*<li className="nav-item mb-sm-3 w-100">*/}
                {/*    <Link to={PageEndpoints.home}>*/}
                {/*        <i className="bi-house"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>*/}
                {/*    </Link>*/}
                {/*</li>*/}

                <li key="company-info" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.company} data-bs-toggle="collapse">
                        <i className="bi-building"></i> <span className="ms-1 d-none d-sm-inline">Company Info</span>
                    </Link>
                </li>

                <li key="financial-reports" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.reports}>
                        <i className="bi-bar-chart"></i> <span
                        className="ms-1 d-none d-sm-inline">{Strings.FINANCIAL_STATEMENTS}</span>
                    </Link>
                </li>

                <li key="performance-health" className="nav-item mb-sm-3 w-100 mt-4 mt-sm-0">
                    <Link to={PageEndpoints.analysis}>
                        <i className="bi-clipboard2-pulse"></i> <span className="ms-1 d-none d-sm-inline">Performance & Prognosis</span>
                    </Link>
                </li>
            </ul>

            <div className="py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
                <Dropdown key="accountMenu" drop={'up-centered'}>
                    <Dropdown.Toggle id="userAccount" variant="white">
                        <picture>
                            <img className="text-primary user-icon"
                                 src={getPublicUrl() + '/images/user-icon.png'}
                                 alt="CFOdigital logo"/>
                        </picture>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                        {/*<Dropdown.Item*/}
                        {/*    key="userSiigoIntegration"*/}
                        {/*    as={Link}*/}
                        {/*    to={PageEndpoints.userSiigoIntegration}>*/}
                        {/*    Siigo integration*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item key="userAccount" as={Link} to={PageEndpoints.userAccount}>Account</Dropdown.Item>
                        <Dropdown.Divider key="userAccountDivider"/>
                        <Dropdown.Item key="userSignOut" as={Link} to={PageEndpoints.userSignOut}>Sign out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}