import * as React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Strings} from "../../locales/strings";

export function ImportExcelReportModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {Strings.REPORT_IMPORT_TITLE_EXCEL}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="uploadFileInput" className="form-label">
                        {Strings.REPORT_SELECT_EXCEL_LABEL}
                    </label>
                    <input
                        accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        className="form-control"
                        id="uploadFileInput"
                        onChange={props.onFileChange}
                        type="file"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button id="uploadButton" disabled variant="primary" onClick={props.onSubmit}>{Strings.IMPORT_BUTTON}</Button>
                <Button variant="outline-secondary" onClick={props.onHide}>{Strings.CANCEL_BUTTON}</Button>
            </Modal.Footer>
        </Modal>
    );
}