import {yupResolver} from "@hookform/resolvers/yup";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {useForm} from "react-hook-form";
import {createSearchParams, useNavigate} from "react-router-dom";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {Company} from "../../api/companies/company";
import {CompanyReportsImportSiigoParams} from "../../api/companies/companyReportsImportSiigoParams";
import {EntitlementTypes} from "../../api/entitlementTypes";
import {ReportStatementList} from "../../api/reports/reportStatementList";
import {toEntriesDict} from "../../api/reports/reportUtils";
import {reportValidationSchema} from "../../api/reports/reportValidationSchema";
import {useAuth} from "../../AuthProvider";
import FormError from "../../components/FormError";
import SubmitButton from "../../components/SubmitButton";
import useFileImport from "../../hooks/useFileImport";
import usePostData from "../../hooks/usePostData";
import useReportAdd from "../../hooks/useReportAdd";
import {Strings} from "../../locales/strings";
import {formatCurrency, parseNumber} from "../../utils/numberUtils";
import {getFieldToFocus, isEmpty} from "../../utils/pageUtils";
import {PageEndpoints} from "../pageEndpoints";
import {PageStatus} from "../pageStatus";
import {ImportExcelReportModal} from "./ImportExcelReportModal";
import {ImportSiigoReportModal} from "./ImportSiigoReportModal";

// Used to calculate subtotals.
const accountFields: any = {
    'subtotalCurrentAssets': [
        'cash',
        'inventory',
        'accounts_receivable',
        'other_current_assets'
    ],
    'subtotalNoncurrentAssets': [
        'ppe',
        'intangibles',
        'other_noncurrent_assets'
    ],
    'subtotalCurrentLiabilities': [
        'accounts_payable',
        'notes_interest_debt',
        'other_accrued_liabilities',
        'other_current_liabilities'
    ],
    'subtotalNoncurrentLiabilities': [
        'noncurrent_liabilities'
    ],
    'subtotalEquity': [
        'equity'
    ],
    'subtotalCashflows': [
        'operating_cashflow',
        'investing_cashflow',
        'financing_cashflow'
    ]
};

const reverseAccountFieldsMap: any = {};

Object.keys(accountFields).forEach(function (key: string) {
    accountFields[key].forEach(function (ind: any) {
        reverseAccountFieldsMap[ind] = key;
    });
});

export function ReportAddForm(
    {company}: {
        company: Company | null
    }) {

    const navigate = useNavigate();
    const {getEntitlement} = useAuth();
    const isImportAuthorized = getEntitlement(EntitlementTypes.REPORTS_IMPORT) || false;

    const companyId = company?.companyId || '';
    const currencyCode = company?.currencyCode || '';

    const {status, message, validationErrors, addReport} = useReportAdd(companyId);

    let entriesMessage = '';
    let fullMessage = message;

    validationErrors.forEach(function (e: any) {
        if (e['field'] === 'entries') {
            entriesMessage += e['errorMessage'] + ' ';
        }
    });

    // If there is an 'entries' message, show it instead of generic message.
    if (entriesMessage) {
        fullMessage = entriesMessage;
    }

    // Totals and subtotals.
    const [currentAssets, setCurrentAssets] = useState("0");
    const [noncurrentAssets, setNoncurrentAssets] = useState("0");
    const [currentLiabilities, setCurrentLiabilities] = useState("0");
    const [noncurrentLiabilities, setNoncurrentLiabilities] = useState("0");
    const [equity, setEquity] = useState("0");
    const [totalAssets, setTotalAssets] = useState("0");
    const [totalLiabilities, setTotalLiabilities] = useState("0");
    const [totalLiabilitiesEquity, setTotalLiabilitiesEquity] = useState("0");
    const [totalCashflows, setTotalCashflows] = useState("0");

    // Used for redirecting and loading the new report.
    const [periodEndDate, setPeriodEndDate] = useState<string>('');

    const {
        register,
        handleSubmit,
        setError,
        setFocus,
        reset,
        getValues,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(reportValidationSchema),
        shouldFocusError: true
    });

    const calculateSubtotals = useCallback((inputId: string | null, value: number | null) => {
        let assetsTotal = 0;
        let liabilitiesTotal = 0;
        let equityTotal = 0;
        let cashflowsTotal = 0;

        // For each subtotal field, get all corresponding child fields.
        Object.keys(accountFields).forEach(function (key) {
            const sectionFields = accountFields[key];
            const entryFields = sectionFields.map((i: string) => `entries.${i}`);
            // console.log(entryFields);

            const sectionValues = getValues(entryFields);
            const entryValues = sectionValues.map((i: any) => parseNumber(i));
            // console.log(entryValues);

            let subTotal = 0;

            for (let i = 0; i < entryFields.length; i++) {
                const fieldName = sectionFields[i];
                const fieldValue = entryValues[i];

                // Current field has not been updated in 'getValues'.
                if (fieldName === inputId) {

                    // Will not be null on field change--only on file upload.
                    if (value !== null) {
                        subTotal += value;
                    }
                } else {
                    subTotal += fieldValue;
                }
            }

            // console.log(`subtotal (${key}): ${subTotal}`);
            const subTotalFormatted = formatCurrency(subTotal, 0, currencyCode);

            if (key === 'subtotalCurrentAssets') {
                assetsTotal += subTotal;
                setCurrentAssets(subTotalFormatted);
            } else if (key === 'subtotalNoncurrentAssets') {
                setNoncurrentAssets(subTotalFormatted);
                assetsTotal += subTotal;
            } else if (key === 'subtotalCurrentLiabilities') {
                liabilitiesTotal += subTotal;
                setCurrentLiabilities(subTotalFormatted);
            } else if (key === 'subtotalNoncurrentLiabilities') {
                liabilitiesTotal += subTotal;
                setNoncurrentLiabilities(subTotalFormatted);
            } else if (key === 'subtotalEquity') {
                equityTotal += subTotal;
                setEquity(subTotalFormatted);
            } else if (key === 'subtotalCashflows') {
                cashflowsTotal += subTotal;
            }

            const totalAssetsFormatted = formatCurrency(assetsTotal, 0, currencyCode);
            setTotalAssets(totalAssetsFormatted);

            const totalLiabilitiesFormatted = formatCurrency(liabilitiesTotal, 0, currencyCode);
            setTotalLiabilities(totalLiabilitiesFormatted);

            const totalLiabilitiesEquityFormatted = formatCurrency(liabilitiesTotal + equityTotal, 0, currencyCode);
            setTotalLiabilitiesEquity(totalLiabilitiesEquityFormatted);

            const totalCashflowsFormatted = formatCurrency(cashflowsTotal, 0, currencyCode);
            setTotalCashflows(totalCashflowsFormatted);

            // TODO: Validate report form after subtotal calculation. Show error if totals do not match.
        });
    }, [currencyCode, getValues]);

    //
    // Import Excel.
    //

    const {
        status: excelImportStatus,
        message: excelImportMessage,
        importFile: importExcel,
        fileContents
    } = useFileImport();
    const [showUploadExcelModal, setShowUploadExcelModal] = useState(false);

    // Keep file reference when modal is removed from the DOM.
    const uploadFilePath = useRef('');
    const uploadFileObj = useRef<File | null>(null);

    /* When the Excel import modal is closed/cancelled, clear all variables. */
    const handleImportExcelClose = () => {
        setShowUploadExcelModal(false);

        // Cancelled. Do not save file name.
        uploadFilePath.current = '';
        uploadFileObj.current = null;
    };

    const onImportExcelFileChange = (e: any) => {
        uploadFilePath.current = e.target.value;
        const uploadButton = document.getElementById('uploadButton');

        if (uploadButton !== null) {
            if (e.target.value !== '') {
                uploadButton.removeAttribute('disabled');
                uploadButton.onclick = handleImportExcelSubmit;
            } else {
                uploadButton.setAttribute('disabled', '');
            }
        }

        const original = e.target.files[0];

        if (!original) {
            uploadFileObj.current = null;
            return;
        }

        // Clone file object before removing modal from DOM.
        uploadFileObj.current = new File([original], original.name, {
            type: original.type,
            lastModified: original.lastModified
        });
    };

    /* Gets data from Excel import form and calls the API. */
    const handleImportExcelSubmit = () => {
        setShowUploadExcelModal(false);
        importExcel(uploadFilePath.current, uploadFileObj.current);
    };

    /* Show excel modal when clicked. */
    const handleImportExcelOpen = () => {
        if (isImportAuthorized) {
            setShowUploadExcelModal(true);
            uploadFilePath.current = '';
            uploadFileObj.current = null;
        }
    };

    // Run only when upload Excel modal is closed.
    useEffect(() => {
        if (!isEmpty(fileContents)) {
            reset(fileContents, {keepDirty: true});
            calculateSubtotals(null, null);

            // Clear file path and obj.
            uploadFilePath.current = '';
            uploadFileObj.current = null;
        }

    }, [fileContents, reset, calculateSubtotals]);

    //
    // Import Siigo.
    //

    const {
        status: siigoImportStatus,
        message: siigoImportMessage,
        submitForm: importSiigo,
        results: siigoData
    } = usePostData();

    // Keep file reference when modal is removed from the DOM.
    const importSiigoData = useRef<any | null>(null);
    const [showUploadSiigoModal, setShowUploadSiigoModal] = useState(false);

    /* When the Siigo import modal is closed/cancelled, clear all variables. */
    const handleImportSiigoClose = () => {
        setShowUploadSiigoModal(false);
        importSiigoData.current = null;
    };

    /* When data is changed on the Siigo modal, local form data is stored. */
    const onImportSiigoFormChange = (e: any) => {
        importSiigoData.current = e;
    };

    /* Gets data from Siigo import form and calls the API. */
    const handleImportSiigoSubmit = () => {
        setShowUploadSiigoModal(false);

        const d = importSiigoData.current;
        const p = new CompanyReportsImportSiigoParams();
        p.year = parseInt(d['import_year']);
        p.quarter = parseInt(d['import_quarter']);

        importSiigo(ApiEndpoints.companyReportsImportSiigo, p.toJson());
    };

    /* Show siigo modal when clicked. */
    const handleImportSiigoOpen = () => {
        if (isImportAuthorized) {
            setShowUploadSiigoModal(true);
            importSiigoData.current = null;
        }
    };

    // Run only when import Siigo modal is closed.
    useEffect(() => {
        if (siigoData != null) {
            // Siigo data is complete report. Convert to entries dictionary.
            const report = siigoData[0];
            const statements = report['statements'];
            const statementList = ReportStatementList.fromJson(statements);
            const entriesDict = toEntriesDict(statementList);

            reset(entriesDict, {keepDirty: true});
            calculateSubtotals(null, null);
            importSiigoData.current = null;
        }
    }, [siigoData, reset, calculateSubtotals]);

    //
    // Entire report form.
    //

    // console.log('errors: ' + JSON.stringify(errors));
    // console.log('isDirty: ' + isDirty);
    // console.log('isValid: ' + isValid);
    // console.log('getValues: ' + getValues('period_end_date'));

    const isFormReady = isDirty && isValid;

    const onCancelClick = (e: any) => {
        e.preventDefault();
        navigate(PageEndpoints.reports);
    };

    const onInputChange = (e: any) => {
        // Anything above tNumber.MAX_SAFE_INTEGER + 1 (9,007,199,254,740,992) can lead to issues.
        const maxLength = 16;
        const str = e.target.value;

        if (str === '-') {
            // First char of negative number.
        } else if (str.match(/^-?\d+$/)) {
            // const num = parseInt(str, 10);
        } else if (str.match(/^-?\d*\.\d+$/)) {
            // const num = parseFloat(str);
        } else {
            // Not valid number. Remove last character.
            e.target.value = str.slice(0, -1);
        }

        if (e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
        }

        // Calculate totals.
        const inputId = e.target.id;

        const ignoreFields = [
            'net_revenues',
            'operating_income',
            'net_income'
        ];

        // Ignore cashflow and income statement. No totals for these.
        if (ignoreFields.includes(inputId)) {
            return;
        }

        calculateSubtotals(inputId, parseNumber(e.target.value));
    };

    const onSubmitHandler = async (data: any) => {

        // Date input includes full date string. Truncate to correct format.
        const endDate = data['period_end_date'].toISOString().split('T')[0];
        setPeriodEndDate(endDate);
        addReport(data);
    };

    useEffect(() => {
        if (status === PageStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, 'period_end_date');
            setFocus(fieldName);

            document.getElementById('reportRoot')?.scrollIntoView({behavior: 'smooth'});

        } else if (status === PageStatus.IsReady) {
            setFocus('period_end_date');
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            // TODO: Redirect with report date so we automatically view this one--if it's not the latest.
            navigate({
                pathname: PageEndpoints.reports,
                search: `?${createSearchParams({
                    period_end_date: periodEndDate
                })}`
            });
        }
    }, [status, periodEndDate, navigate]);

    return (
        <div>
            <ImportExcelReportModal
                show={showUploadExcelModal}
                onHide={handleImportExcelClose}
                onSubmit={handleImportExcelSubmit}
                onFileChange={onImportExcelFileChange}
            />
            <ImportSiigoReportModal
                show={showUploadSiigoModal}
                onHide={handleImportSiigoClose}
                onSubmit={handleImportSiigoSubmit}
                onFormChange={onImportSiigoFormChange}
            />
            <FormError status={status} message={fullMessage}/>
            <FormError status={excelImportStatus} message={excelImportMessage}/>
            <FormError status={siigoImportStatus} message={siigoImportMessage}/>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <fieldset id="me" disabled={status === PageStatus.IsSubmitting}>
                    <input {...register("company_id", {value: ""})} type="hidden"/>
                    <input {...register("created_by", {value: ""})} type="hidden"/>

                    <div className="row">
                        <div className="col-12">
                            <h3>Report Info</h3>
                        </div>
                        <div className="col">
                            <div>
                                <label htmlFor="period_end_date" className="form-label">Period end date</label>
                                <input {...register('period_end_date')} type="date" id="period_end_date"
                                       className="form-control" placeholder=""/>
                                <small className="text-danger">
                                    {errors?.period_end_date?.message?.toString()}
                                </small>
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <label htmlFor="quarter" className="form-label">Quarter</label>
                                <select {...register('quarter')} id="quarter" className="form-select">
                                    <option value="">Select quarter...</option>
                                    <option value="1" key="1">Q1</option>
                                    <option value="2" key="2">Q2</option>
                                    <option value="3" key="3">Q3</option>
                                    <option value="4" key="4">Q4</option>
                                </select>

                                <small className="text-danger">
                                    {errors?.quarter?.message?.toString()}
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-primary">
                                <ul>
                                    <li>{Strings.REPORT_INFLOWS_NOTE}</li>
                                    <li>{Strings.REPORT_OUTFLOWS_NOTE}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col">
                                    <h3>Balance Sheet</h3>
                                </div>
                                <div className="col-auto">
                                    {
                                        isImportAuthorized ?
                                            (
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className="import"
                                                        disabled={excelImportStatus === PageStatus.IsSubmitting || siigoImportStatus === PageStatus.IsSubmitting}
                                                        size="sm"
                                                        variant="primary"
                                                    >
                                                        {Strings.REPORT_IMPORT_BUTTON}
                                                        {(excelImportStatus === PageStatus.IsSubmitting || siigoImportStatus === PageStatus.IsSubmitting) &&
                                                            <span className="spinner">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true">
                                                            </span>
                                                            </span>
                                                        }
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            as="button"
                                                            onClick={handleImportExcelOpen}>
                                                            {Strings.OPTION_EXCEL}
                                                        </Dropdown.Item>

                                                        {/*<Dropdown.Item*/}
                                                        {/*    as="button"*/}
                                                        {/*    onClick={handleImportSiigoOpen}>*/}
                                                        {/*    {Strings.OPTION_SIIGO}*/}
                                                        {/*</Dropdown.Item>*/}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : <></>
                                    }

                                    {!isImportAuthorized ?
                                        (
                                            <OverlayTrigger
                                                placement="auto"
                                                delay={{show: 0, hide: 700}}
                                                overlay={(
                                                    <Popover>
                                                        <Popover.Body>
                                                            {Strings.FEATURE_DISABLED}
                                                        </Popover.Body>
                                                    </Popover>
                                                )}>
                                                <Button variant="primary" size="sm">
                                                    {Strings.REPORT_IMPORT_BUTTON}
                                                </Button>
                                            </OverlayTrigger>
                                        ) : <></>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <h4>Current Assets</h4>
                            <div>
                                <label htmlFor="cash" className="form-label">Cash</label>
                                <input {...register('entries.cash')}
                                       id="cash"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.cash?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="inventory" className="form-label">Inventory</label>
                                <input {...register('entries.inventory')}
                                       id="inventory"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.inventory?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="accounts_receivable" className="form-label">Accounts
                                    Receivable</label>
                                <input {...register('entries.accounts_receivable')}
                                       id="accounts_receivable"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.accounts_receivable?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="other_current_assets" className="form-label">
                                    Other Current Assets
                                </label>
                                <input {...register('entries.other_current_assets')}
                                       id="other_current_assets"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.other_current_assets?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals">
                                <label htmlFor="subtotalCurrentAssets" className="form-label">
                                    Subtotal Current Assets
                                </label>
                                <input
                                    id="subtotalCurrentAssets"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={currentAssets || 0}
                                />
                            </div>

                            <h4>Noncurrent Assets</h4>

                            <div>
                                <label htmlFor="ppe" className="form-label">
                                    Property, Plant and Equipment
                                </label>
                                <input {...register('entries.ppe')}
                                       id="ppe"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.ppe?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="intangibles" className="form-label">Intangibles</label>
                                <input {...register('entries.intangibles')}
                                       id="intangibles"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.intangibles?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="other_noncurrent_assets" className="form-label">
                                    Other Noncurrent Assets
                                </label>
                                <input {...register('entries.other_noncurrent_assets')}
                                       id="other_noncurrent_assets"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.other_noncurrent_assets?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals">
                                <label htmlFor="subtotalNoncurrentAssets" className="form-label">
                                    Subtotal Noncurrent Assets
                                </label>
                                <input
                                    id="subtotalNoncurrentAssets"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={noncurrentAssets || 0}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <h4>Liabilities</h4>

                            <div>
                                <label htmlFor="accounts_payable" className="form-label">
                                    Accounts Payable
                                </label>
                                <input {...register('entries.accounts_payable')}
                                       id="accounts_payable"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.accounts_payable?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="notes_interest_debt" className="form-label">
                                    Interest, Notes and Debt Payable
                                </label>
                                <input {...register('entries.notes_interest_debt')}
                                       id="notes_interest_debt"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.notes_interest_debt?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="other_accrued_liabilities" className="form-label">
                                    Other Accrued Liabilities
                                </label>
                                <input {...register('entries.other_accrued_liabilities')}
                                       id="other_accrued_liabilities"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.other_accrued_liabilities?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="other_current_liabilities" className="form-label">
                                    Other Current Liabilities
                                </label>
                                <input {...register('entries.other_current_liabilities')}
                                       id="other_current_liabilities"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.other_current_liabilities?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals">
                                <label htmlFor="subtotalCurrentLiabilities" className="form-label">
                                    Subtotal Current Liabilities
                                </label>
                                <input
                                    id="subtotalCurrentLiabilities"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={currentLiabilities || 0}
                                />
                            </div>

                            <div>
                                <label htmlFor="noncurrent_liabilities" className="form-label">
                                    Noncurrent Liabilities
                                </label>
                                <input {...register('entries.noncurrent_liabilities')}
                                       id="noncurrent_liabilities"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.noncurrent_liabilities?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals d-none">
                                <label htmlFor="subtotalNoncurrentLiabilities" className="form-label">
                                    Subtotal Noncurrent Liabilities
                                </label>
                                <input
                                    id="subtotalNoncurrentLiabilities"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={noncurrentLiabilities || 0}
                                />
                            </div>

                            <div className="reports-totals">
                                <label htmlFor="totalLiabilities" className="form-label">
                                    Total Liabilities
                                </label>
                                <input
                                    id="totalLiabilities"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={totalLiabilities || 0}
                                />
                            </div>

                            <h4>Equity</h4>

                            <div>
                                <label htmlFor="equity" className="form-label">Equity</label>
                                <input {...register('entries.equity')}
                                       id="equity"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.equity?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals d-none">
                                <label htmlFor="subtotalEquity" className="form-label">
                                    Subtotal Equity
                                </label>
                                <input
                                    id="subtotalEquity"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={equity || 0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div className="reports-totals">
                                <label htmlFor="totalAssets" className="form-label">
                                    Total Assets
                                </label>
                                <input
                                    id="totalAssets"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={totalAssets || 0}
                                />
                                <small className="text-danger">
                                    {
                                        (totalAssets !== totalLiabilitiesEquity) ? "Assets must equal Liabilities + Equity." : ""
                                    }
                                </small>
                            </div>
                        </div>
                        <div className="col">
                            <div className="reports-totals">
                                <label htmlFor="totalLiabilitiesEquity" className="form-label">
                                    Total Liabilities + Equity
                                </label>
                                <input
                                    id="totalLiabilitiesEquity"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={totalLiabilitiesEquity || 0}
                                />
                                <small className="text-danger">
                                    {
                                        (totalAssets !== totalLiabilitiesEquity) ? "Assets must equal Liabilities + Equity." : ""
                                    }
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h3>Cashflow Statement</h3>
                            <div>
                                <label htmlFor="operating_cashflow" className="form-label">
                                    Quarterly Operating Cashflow
                                </label>
                                <input {...register('entries.operating_cashflow')}
                                       id="operating_cashflow"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.operating_cashflow?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="investing_cashflow" className="form-label">
                                    Quarterly Investing Cashflow
                                </label>
                                <input {...register('entries.investing_cashflow')}
                                       id="investing_cashflow"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.investing_cashflow?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="financing_cashflow" className="form-label">
                                    Quarterly Financing Cashflow
                                </label>
                                <input {...register('entries.financing_cashflow')}
                                       id="financing_cashflow"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.financing_cashflow?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div className="reports-totals">
                                <label htmlFor="totalCashflows" className="form-label">
                                    Quarterly Net Cashflows
                                </label>
                                <input
                                    id="totalCashflows"
                                    className="form-control"
                                    placeholder="0"
                                    disabled
                                    value={totalCashflows || 0}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <h3>Income Statement</h3>
                            <div>
                                <label htmlFor="net_revenues" className="form-label">
                                    Quarterly Net Revenues
                                </label>
                                <input {...register('entries.net_revenues')}
                                       id="net_revenues"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.net_revenues?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="operating_income" className="form-label">
                                    Quarterly Operating Income
                                </label>
                                <input {...register('entries.operating_income')}
                                       id="operating_income"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.operating_income?.message?.toString()
                                    }
                                </small>
                            </div>

                            <div>
                                <label htmlFor="net_income" className="form-label">
                                    Quarterly Net Income
                                </label>
                                <input {...register('entries.net_income')}
                                       id="net_income"
                                       className="form-control"
                                       placeholder="123"
                                       onChange={onInputChange}/>
                                <small className="text-danger">
                                    {
                                        // @ts-expect-error Just skip.
                                        errors?.entries?.net_income?.message?.toString()
                                    }
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <hr/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>
                                <SubmitButton
                                    isFormReady={isFormReady}
                                    featureStatus={status}
                                    text="Add report"
                                />
                                <button className="btn btn-lg btn-outline-secondary"
                                        onClick={onCancelClick}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}