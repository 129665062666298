import * as React from 'react';
import {Strings} from "../../locales/strings";

export default function SiigoAppIntegrationHeader() {

    return (
        <div className="header">
            <div className="row align-items-end d-flex align-items-center">
                <div className="col">
                    <h6 className="header-pretitle">
                        {Strings.PAGE_HEADER_OVERVIEW}
                    </h6>
                    <h1 className="header-title">
                        {Strings.SIIGO_PAGE_HEADER}
                    </h1>
                </div>
            </div>
        </div>
    );
}