export function getValueByKey(obj: any, key: string): any | null {
    if (obj !== null && obj instanceof Map && obj.has(key)) {
        return obj.get(key);
    } else if (obj !== null && key in obj) {
        return obj[key];
    } else {
        // Handle the case where the object is null or the key doesn't exist
        return null;
    }
}

/* Converts a nested Map to an Object. */
export function mapToObject(map = new Map()): any {
    return Object.fromEntries(Array.from(map.entries()
            , ([k, v]) =>
                v instanceof Map
                    ? [k, mapToObject(v)]
                    : [k, v]
        )
    );
}


// =>
//     Object.fromEntries(Array.from(map.entries()
//             , ([k, v]) =>
//                 v instanceof Map
//                     ? [k, mapToObject(v)]
//                     : [k, v]
//         )
//     );
