import {yupResolver} from "@hookform/resolvers/yup";
import * as React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import {importSiigoReportValidationSchema, MAX_REPORT_YEARS} from "../../api/reports/reportValidationSchema";
import {Strings} from "../../locales/strings";

export function ImportSiigoReportModal(props: any) {

    const currentYear = new Date().getFullYear();
    const reportYears: number[] = [];
    const reportQuarters: number[] = [1, 2, 3, 4];

    // Only allow the last n years.
    for (let i = currentYear; i >= currentYear - MAX_REPORT_YEARS + 1; i--) {
        reportYears.push(i);
    }

    const {
        register,
        formState: {errors, isDirty, isValid},
        reset: resetSiigo,
        getValues
    } = useForm({
        mode: 'all',
        resolver: yupResolver(importSiigoReportValidationSchema),
        shouldFocusError: true
    });

    const handleShow = () => {
        resetSiigo();
    };

    const isFormReady = isDirty && isValid;

    // Signal to parent form when data is ready.
    if (isFormReady) {
        props.onFormChange(getValues());
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onShow={handleShow}
        >
            <form>
                <fieldset>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {Strings.REPORT_IMPORT_TITLE_SIIGO}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label
                                            htmlFor="importReportYear"
                                            className="form-label">
                                            {Strings.REPORT_YEAR}
                                        </label>
                                        <select
                                            {...register('import_year')}
                                            id="importReportYear"
                                            className="form-select"
                                            defaultValue=""
                                        >
                                            <option key="" value="">{Strings.SELECT_REPORT_YEAR}</option>
                                            {
                                                reportYears.map(key => (
                                                    <option key={key} value={key}>
                                                        {key}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <small className="text-danger">
                                            {errors?.import_year?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <label
                                            htmlFor="importReportQuarter"
                                            className="form-label">
                                            {Strings.REPORT_QUARTER}
                                        </label>
                                        <select
                                            {...register('import_quarter')}
                                            id="importReportQuarter"
                                            className="form-select"
                                            defaultValue=""
                                        >
                                            <option key="" value="">{Strings.SELECT_REPORT_QUARTER}</option>
                                            {
                                                reportQuarters.map(key => (
                                                    <option key={key} value={key}>
                                                        {key}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <small className="text-danger">
                                            {errors?.import_quarter?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id="importSiigoButton"
                            disabled={!isFormReady}
                            variant="primary"
                            onClick={props.onSubmit}>{Strings.IMPORT_BUTTON}</Button>
                        <Button
                            variant="outline-secondary"
                            onClick={props.onHide}>{Strings.CANCEL_BUTTON}</Button>
                    </Modal.Footer>
                </fieldset>
            </form>
        </Modal>
    );
}