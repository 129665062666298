export const Strings = {
    COMPANY_NAME: "FinRatio Corp.",

    FINANCIAL_STATEMENTS: "Financial Statements",

    ERROR_SOMETHING: "Something went wrong.",
    ERROR_UNEXPECTED: "An unexpected error has occurred.",

    CHART_GENERATING_ANALYSIS: "Generating analysis...",

    // Common - Page headers.
    PAGE_HEADER_OVERVIEW: "Overview",

    NOT_NOW_BUTTON: "Not now",
    CANCEL_BUTTON: "Cancel",
    IMPORT_BUTTON: "Import",
    NOT_SELECTED_OPTION: "-- Not selected --",

    REPORT_DELETE_BUTTON: "Delete report",
    REPORT_DELETE_WARNING: "Warning: This action is permanent and cannot be undone.",

    REPORT_IMPORT_BUTTON: "Import statements",
    REPORT_IMPORT_TITLE_EXCEL: "Import Excel File",
    REPORT_IMPORT_TITLE_SIIGO: "Import Siigo Report",

    REPORT_SELECT_EXCEL_LABEL: "Select Excel input file",
    REPORT_EXPORT_BUTTON: "Export report",

    REPORT_INFLOWS_NOTE: "Use positive numbers for gains and cash inflows.",
    REPORT_OUTFLOWS_NOTE: "Use negative numbers for losses and cash outflows.",

    USER_SUBSCRIPTION_CANCEL_BUTTON: "Cancel subscription",
    USER_SUBSCRIPTION_CANCEL_SUCCESS: "Your subscription has been canceled!",
    USER_SUBSCRIPTION_CANCEL_TITLE: "Cancel subscription",
    USER_SUBSCRIPTION_RENEWS_MONTHLY: "Automatically renews monthly. Cancel anytime.",
    USER_SUBSCRIPTION_RENEWS_YEARLY: "Automatically renews yearly. Cancel anytime.",
    USER_SUBSCRIPTION_SUCCESS: "Subscription successful! Get started!",

    // App integration.
    INTEGRATION_ACCOUNT_TAB: "Account",
    INTEGRATION_TEMPLATE_TAB: "Template",
    INTEGRATION_SAVE_CREDENTIALS_BUTTON: "Save credentials",
    INTEGRATION_LOAD_TEMPLATE_BUTTON: "Load template",
    INTEGRATION_SAVE_TEMPLATE_BUTTON: "Save template",

    INTEGRATION_DISCONNECT_BUTTON: "Disconnect",
    INTEGRATION_DISCONNECT_LABEL: "Disconnect your account",
    INTEGRATION_DISCONNECT_MESSAGE_SIIGO: "Disconnecting your account will delete your saved credentials, which will prevent importing from Siigo Online.",

    // Integration form.
    SIIGO_PAGE_HEADER: 'Siigo App Integration',
    SIIGO_USERNAME: 'Siigo username',
    SIIGO_ACCESS_KEY: 'Siigo access key',

    FEATURE_DISABLED: "This feature is disabled for your plan. To activate it,\ncontact FinRatio to upgrade your account.",
    FEATURE_LIMIT: "You have reached your subscription limit. Contact FinRatio to upgrade your account.",

    // Import reports.
    REPORT_YEAR: 'Report year',
    SELECT_REPORT_YEAR: 'Select year...',
    REPORT_QUARTER: 'Report quarter',
    SELECT_REPORT_QUARTER: 'Select quarter...',

    OPTION_EXCEL: 'Excel spreadsheet',
    OPTION_SIIGO: 'Siigo accounting'
};