import {yupResolver} from "@hookform/resolvers/yup";
import * as React from "react";
import {useEffect, useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {AccountingTemplate} from "../../api/integration/accountingTemplate";
import {AccountingTemplateEntry} from "../../api/integration/accountingTemplateEntry";
import {AccountingTemplateEntryList} from "../../api/integration/accountingTemplateEntryList";
import {IntegrationLoadTemplateParams} from "../../api/integration/integrationLoadTemplateParams";
import {Account} from "../../api/misc/account";
import {AccountList} from "../../api/misc/accountList";
import {importSiigoReportValidationSchema, MAX_REPORT_YEARS} from "../../api/reports/reportValidationSchema";
import {UserSetting} from "../../api/user/userSetting";
import {UserSettingsUpdateParams} from "../../api/user/userSettingsUpdateParams";
import {userSiigoIntegrationSchema} from "../../api/user/userValidationSchema";
import {useAuth} from "../../AuthProvider";
import FormError from "../../components/FormError";
import FormSuccess from "../../components/FormSuccess";
import SubmitButton from "../../components/SubmitButton";
import usePostData from "../../hooks/usePostData";
import {Strings} from "../../locales/strings";
import {PageStatus} from "../pageStatus";

const currentYear = new Date().getFullYear();
const reportYears: number[] = [];
const reportQuarters: number[] = [1, 2, 3, 4];

// Only allow the last n years.
for (let i = currentYear; i >= currentYear - MAX_REPORT_YEARS + 1; i--) {
    reportYears.push(i);
}

export function SiigoAppIntegration() {
    const {getUser} = useAuth();

    //
    // Chart of accounts key/name list.
    //

    const [accountList, setAccountList] = useState<AccountList | null>(null);

    const {
        status: accountListStatus,
        submitForm: accountListSubmit,
        results: accountListResults
    } = usePostData();

    // Fetch utility data.
    useEffect(() => {
        if (accountListStatus === PageStatus.IsReady) {
            accountListSubmit(ApiEndpoints.accountsList, {}, true, false);
        }

    }, [accountListStatus, accountListSubmit]);

    // Load utility data after fetching.
    useEffect(() => {
        if (accountListStatus === PageStatus.HasSubmitted &&
            accountList === null) {

            const accounts = AccountList.fromJson(accountListResults);

            const account = new Account();
            account.accountKey = '';
            account.accountName = Strings.NOT_SELECTED_OPTION;
            accounts.unshift(account);

            setAccountList(accounts);
        }
    }, [accountListStatus, accountList, accountListResults]);

    //
    // Account tab.
    //

    const {
        status: saveCredentialsStatus,
        message: saveCredentialsMessage,
        submitForm: saveCredentialsSubmit
    } = usePostData();

    const {
        register: registerCredentials,
        handleSubmit: handleCredentialsSubmit,
        reset: resetCredentials,
        formState: {
            errors: credentialsErrors,
            isDirty: credentialsIsDirty,
            isValid: credentialsIsValid
        }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userSiigoIntegrationSchema),
        shouldFocusError: true
    });

    // On page load, load form with existing settings.
    useEffect(() => {
        const user = getUser();

        if (user == null) {
            return;
        }

        const settings = user.settings;
        resetCredentials(settings.toDict());

    }, [getUser, resetCredentials]);

    const isCredentialsFormReady = credentialsIsDirty && credentialsIsValid;

    const onSubmitCredentialsHandler = async (data: any) => {
        const p = new UserSettingsUpdateParams();

        Object.entries(data).forEach(([key, value]) => {
            const us = new UserSetting();
            us.settingKey = key;
            us.settingValue = value;

            p.settings.push(us);
        });

        saveCredentialsSubmit(ApiEndpoints.userSettingsUpdate, p.toJson(), true, true);
    };

    //
    // Template tab.
    //

    // 2 forms (steps):
    // - Load template based on year and quarter.
    // - Save template.

    //
    // Get template.
    //

    // const at = new AccountingTemplateEntryList();
    // const ate1 = new AccountingTemplateEntry();
    // ate1.reportAccountCode = '11';
    // ate1.reportAccountName = 'Cash';
    // ate1.targetAccountKey = 'cash';
    // at.push(ate1);
    //
    // const ate2 = new AccountingTemplateEntry();
    // ate2.reportAccountCode = '12';
    // ate2.reportAccountName = 'Other Current Assets';
    // ate2.targetAccountKey = 'other_current_assets';
    // at.push(ate2);
    //
    // const ate3 = new AccountingTemplateEntry();
    // ate3.reportAccountCode = '14';
    // ate3.reportAccountName = 'Inventory';
    // ate3.targetAccountKey = 'inventory';
    // at.push(ate3);

    const [entryList, setEntryList] = useState<AccountingTemplateEntryList | null>(null);

    const {
        register: registerLoad,
        handleSubmit: handleLoadSubmit,
        formState: {
            errors: loadErrors,
            isDirty: isLoadDirty,
            isValid: isLoadValid
        }
    } = useForm({
        mode: 'all',
        resolver: yupResolver(importSiigoReportValidationSchema),
        shouldFocusError: true
    });

    // TODO: Only allow loading when credentials have been saved. Show message if not yet saved.
    const isLoadReady = isLoadDirty && isLoadValid;

    const {
        status: loadTemplateStatus,
        message: loadTemplateMessage,
        submitForm: submitLoad,
        results: loadTemplateResults
    } = usePostData();

    // TODO: Add a note what load template does--i.e., loads current template compared to selected report.
    const onSubmitLoad = async (data: any) => {

        // Clear any changes before loading.
        setEntryList(null);

        const p = new IntegrationLoadTemplateParams();
        p.year = data.import_year;
        p.quarter = data.import_quarter;

        submitLoad(ApiEndpoints.integrationAccountingTemplateGet, p.toJson(), true, false);
    };

    // Load accounting template after fetch.
    useEffect(() => {
        if (loadTemplateStatus === PageStatus.HasSubmitted && loadTemplateResults != null && entryList == null) {
            if (loadTemplateResults.length > 0) {
                // setEntryList(AccountingTemplate.fromJson(loadTemplateResults[0]).entries);

                // TODO: Temp filter.
                const x =
                    AccountingTemplate.fromJson(loadTemplateResults[0]).entries.filter((o: AccountingTemplateEntry) =>
                        o.reportAccountCode.startsWith('1') && o.reportAccountCode.length <= 4);
                setEntryList(x);

                // Sync with form.
                for (let i = 0; i < x.length; i++) {
                    setValue(`selections.${i}.value`, x[i].targetAccountKey || '');
                }
            }
        }
    }, [loadTemplateStatus, loadTemplateResults, entryList]);

    //
    // Save template.
    //

    // TODO: Move and rename schema. Add more rules.
    const schema = Yup.object().shape({
        selections: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string()
                        .required("Please select an option")
                        .notOneOf([""], "Selection cannot be empty") // Ensures no empty string
                })
            )
            .min(1, "At least one selection is required")
    });

    // TODO: Rename all.
    const {
        control,
        handleSubmit: handleSaveSubmit,
        setValue, // Added to programmatically set values.
        formState: {
            errors: saveErrors,
            isDirty: isSaveDirty,
            isValid: isSaveValid
        }
    } = useForm({
        defaultValues: {
            selections: entryList?.map(item => ({value: item.targetAccountKey}))
        },
        mode: 'all',
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    const isSaveReady = isSaveDirty && isSaveValid;

    // TODO: Delete logging when done.
    console.log('isSaveDirty: ' + isSaveDirty);
    console.log('isSaveValid: ' + isSaveValid);
    console.log('SaveErrors: ' + JSON.stringify(saveErrors));

    const {
        status: saveTemplateStatus,
        message: saveTemplateMessage,
        submitForm: submitSaveTemplate,
        results: saveTemplateResults
    } = usePostData();

    const handleAccountChange = (index: any, value: any) => {
        if (entryList === null) {
            return;
        }

        // Sync change with user state.
        const newSelectList = [...entryList];
        newSelectList[index].targetAccountKey = value;
        setEntryList(newSelectList);

        // Sync change with form.
        setValue(`selections.${index}.value`, value);

        // TODO: Not selected = '', pass_through,
        // TODO: When selects are changed, change above and below.
        const changedItem = newSelectList[index];
        const descendants = changedItem.descendants;
        const accountKey = changedItem.targetAccountKey;

        for (let i = 0; i < newSelectList.length; i++) {
            // Skip changed item.
            if (i === index) {
                continue;
            }

            const currentItem = newSelectList[i];

            // For all descendants, set the account key to match the item.
            if (descendants.includes(currentItem.reportAccountCode)) {
                currentItem.targetAccountKey = accountKey;
                setValue(`selections.${i}.value`, accountKey);
            }
        }

        // TODO: Update state?
    };

    // TODO: Replace with save hook.
    const saveStatus = PageStatus.IsReady;

    const onSubmitSave = (data: any) => {
        console.log(JSON.stringify(data));
    };

    // TODO: Add note: "To reset the form, just reload it."
    // TODO: Add errors.
    // {errors.selections?.[index]?.value && (
    //     <span style={{ color: "red" }}>
    //           {errors.selections[index].value.message}
    //         </span>
    // )}

    return (
        <>
            <Tabs
                defaultActiveKey="template"
                className="mb-3"
            >
                <Tab eventKey="account" title={Strings.INTEGRATION_ACCOUNT_TAB}>
                    <FormError status={saveCredentialsStatus} message={saveCredentialsMessage}/>
                    <FormSuccess status={saveCredentialsStatus} message={saveCredentialsMessage}/>

                    <form onSubmit={handleCredentialsSubmit(onSubmitCredentialsHandler)}>
                        <fieldset id="credentials" disabled={saveCredentialsStatus === PageStatus.IsSubmitting}>
                            <div className="row">
                                <div className="col-4">
                                    <div>
                                        <label htmlFor="siigo_username"
                                               className="form-label">{Strings.SIIGO_USERNAME}</label>
                                        <input {...registerCredentials('siigo_username')}
                                               type="text"
                                               id="siigo_username"
                                               className="form-control"
                                               placeholder={Strings.SIIGO_USERNAME}/>
                                        <small className="text-danger">
                                            {credentialsErrors?.siigo_username?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div>
                                        <label htmlFor="siigo_access_key"
                                               className="form-label">{Strings.SIIGO_ACCESS_KEY}</label>
                                        <input {...registerCredentials('siigo_access_key')}
                                               type="text"
                                               id="siigo_access_key"
                                               className="form-control"
                                               placeholder={Strings.SIIGO_ACCESS_KEY}/>
                                        <small className="text-danger">
                                            {credentialsErrors?.siigo_access_key?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <SubmitButton
                                        isFormReady={isCredentialsFormReady}
                                        featureStatus={saveCredentialsStatus}
                                        text={Strings.INTEGRATION_SAVE_CREDENTIALS_BUTTON}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </Tab>

                <Tab eventKey="template" title={Strings.INTEGRATION_TEMPLATE_TAB}>
                    <FormError status={loadTemplateStatus} message={loadTemplateMessage}/>
                    <FormSuccess status={loadTemplateStatus} message={loadTemplateMessage}/>

                    <form onSubmit={handleLoadSubmit(onSubmitLoad)}>
                        <fieldset id="loadTemplate" disabled={loadTemplateStatus === PageStatus.IsSubmitting}>
                            <div className="row">
                                <div className="col-3">
                                    <div>
                                        <label
                                            htmlFor="importReportYear"
                                            className="form-label">
                                            {Strings.REPORT_YEAR}
                                        </label>
                                        <select
                                            {...registerLoad('import_year')}
                                            id="importReportYear"
                                            className="form-select"
                                            defaultValue=""
                                        >
                                            <option key="" value="">{Strings.SELECT_REPORT_YEAR}</option>
                                            {
                                                reportYears.map(key => (
                                                    <option key={key} value={key}>
                                                        {key}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <small className="text-danger">
                                            {loadErrors?.import_year?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div>
                                        <label
                                            htmlFor="importReportQuarter"
                                            className="form-label">
                                            {Strings.REPORT_QUARTER}
                                        </label>
                                        <select
                                            {...registerLoad('import_quarter')}
                                            id="importReportQuarter"
                                            className="form-select"
                                            defaultValue=""
                                        >
                                            <option key="" value="">{Strings.SELECT_REPORT_QUARTER}</option>
                                            {
                                                reportQuarters.map(key => (
                                                    <option key={key} value={key}>
                                                        {key}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        <small className="text-danger">
                                            {loadErrors?.import_quarter?.message?.toString()}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">
                                    <SubmitButton
                                        isFormReady={isLoadReady}
                                        featureStatus={loadTemplateStatus}
                                        text={Strings.INTEGRATION_LOAD_TEMPLATE_BUTTON}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>

                    <hr/>

                    {
                        entryList && accountList &&
                        <form onSubmit={handleSaveSubmit(onSubmitSave)}>
                            <fieldset id="saveTemplate" disabled={saveTemplateStatus === PageStatus.IsSubmitting}>
                                <table className="table table-hover">
                                    <thead>
                                    <tr>
                                        <th colSpan={3} className="text-center">Account Code</th>
                                        <th className="text-center">Account Description</th>
                                        <th className="text-center" colSpan={4}>Template Target Account</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        entryList.map((item, index) => (
                                            <tr key={item.reportAccountCode}>
                                                <td className="align-middle">
                                                    {
                                                        item.level === 1 &&
                                                        item.reportAccountCode
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        item.level === 2 &&
                                                        item.reportAccountCode
                                                    }
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        item.level === 3 &&
                                                        item.reportAccountCode
                                                    }
                                                </td>
                                                <td className="align-middle">{item.reportAccountName}</td>

                                                {Array.from({length: item.level}).map((_, index) => (
                                                    <td className={`x-ms-${index + 1}`}
                                                        key={`spacer-${index}`}>&nbsp;</td>
                                                ))}

                                                <td colSpan={5 - item.level} className="align-middle">
                                                    <Controller
                                                        name={`selections.${index}.value`}
                                                        control={control}
                                                        rules={{required: "Please select an option"}}
                                                        render={({field}) => (
                                                            <select
                                                                {...field}
                                                                className="form-select form-select-sm"
                                                                value={entryList[index].targetAccountKey || ''} // Controlled by selectList state
                                                                onChange={(e) => {
                                                                    field.onChange(e); // Update form state
                                                                    handleAccountChange(index, e.target.value); // Update local state
                                                                }}
                                                            >
                                                                {accountList.map((option) => (
                                                                    <option key={option.accountKey}
                                                                            value={option.accountKey}>
                                                                        {option.accountName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {
                                    entryList &&
                                    <SubmitButton
                                        isFormReady={isSaveReady}
                                        featureStatus={saveStatus}
                                        text={Strings.INTEGRATION_SAVE_TEMPLATE_BUTTON}
                                        additionalClassName="mt-3"
                                    />
                                }
                            </fieldset>
                        </form>
                    }
                </Tab>
            </Tabs>
        </>
    );
}
