import * as Yup from "yup";
import {
    NUMBER_REQUIRED,
    PERIOD_END_DATE_INVALID,
    PERIOD_END_DATE_MAX,
    PERIOD_END_DATE_MIN,
    PERIOD_END_DATE_REQUIRED,
    QUARTER_REQUIRED,
    YEAR_REQUIRED
} from "../../locales/validationMessages";
import {subtractYears} from "../../utils/dateUtils";

export const MAX_REPORT_YEARS = 5;

const minDate = subtractYears(new Date(), MAX_REPORT_YEARS);

export const importSiigoReportValidationSchema = Yup.object().shape({
    import_year: Yup.string()
        .required(YEAR_REQUIRED),
    import_quarter: Yup.string()
        .required(QUARTER_REQUIRED)
});

export const reportValidationSchema = Yup.object().shape({
    period_end_date: Yup.date()
        .min(minDate, PERIOD_END_DATE_MIN)
        .max(new Date(), PERIOD_END_DATE_MAX)
        .typeError(PERIOD_END_DATE_INVALID)
        .required(PERIOD_END_DATE_REQUIRED),
    quarter: Yup.string()
        .required(QUARTER_REQUIRED),
    entries: Yup.object().shape({
        cash: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        inventory: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        accounts_receivable: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        other_current_assets: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        ppe: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        intangibles: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        other_noncurrent_assets: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),

        accounts_payable: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        notes_interest_debt: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        other_accrued_liabilities: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        other_current_liabilities: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        noncurrent_liabilities: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),

        equity: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),

        operating_cashflow: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        investing_cashflow: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        financing_cashflow: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),

        net_revenues: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        operating_income: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED),
        net_income: Yup.number()
            .typeError(NUMBER_REQUIRED)
            .required(NUMBER_REQUIRED)
    })
});