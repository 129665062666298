export class Account {
    accountKey: string = '';
    accountName: string = '';

    static fromJson(json: any): Account {
        const obj = new Account();

        obj.accountKey = json.account_key;
        obj.accountName = json.account_name;

        return obj;
    }
}