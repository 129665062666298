export class AccountingTemplateEntry {
    reportAccountCode: string = '';
    reportAccountParentCode: string | null = null;
    reportAccountName: string | null = null;

    sourceAccountCode: string | null = null;
    sourceAccountParentCode: string | null = null;

    targetAccountKey: string | null = null;
    targetAccountName: string | null = null;

    balance: number = 0;
    isHandled: boolean = false;
    level: number = 1;
    ancestors: string[] = [];
    descendants: string[] = [];

    static fromJson(json: any): AccountingTemplateEntry {
        const obj = new AccountingTemplateEntry();

        obj.reportAccountCode = json.report_account_code;
        obj.reportAccountParentCode = json.report_account_parent_code;
        obj.reportAccountName = json.report_account_name;
        obj.sourceAccountCode = json.source_account_code;
        obj.sourceAccountParentCode = json.source_account_parent_code;
        obj.targetAccountKey = json.target_account_key;
        obj.targetAccountName = json.target_account_name;
        obj.balance = json.balance;
        obj.isHandled = json.is_handled;
        obj.level = json.level;
        obj.ancestors = json.ancestors;
        obj.descendants = json.descendants;

        return obj;
    }
}
