import {AccountingTemplateEntryList} from "./accountingTemplateEntryList";

export class AccountingTemplate {
    entries: AccountingTemplateEntryList = new AccountingTemplateEntryList();

    static fromJson(json: any): AccountingTemplate {
        const obj = new AccountingTemplate();

        if ('entries' in json) {
            obj.entries = AccountingTemplateEntryList.fromJson(json.entries);
        }

        return obj;
    }
}
