export const NUMBER_REQUIRED = 'Value is required. Enter 0 if no value.';

export const PERIOD_END_DATE_INVALID = 'Period end date is not valid.';
export const PERIOD_END_DATE_REQUIRED = 'Period end date is required';
export const PERIOD_END_DATE_MAX = 'Period end date cannot be in the future.';
export const PERIOD_END_DATE_MIN = 'Period end date cannot be more than 5 years ago.';

export const QUARTER_REQUIRED = 'Quarter is required.';

export const YEAR_REQUIRED = 'Year is required.';
