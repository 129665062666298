import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Strings} from "../locales/strings";

export default function GenericModal(
    {show, setShow, buttonText, messageText, doConfirmation}: {
        show: boolean,
        setShow: any,
        buttonText: string,
        messageText: string[],
        doConfirmation: any
    }) {

    const handleDisregard = () => {
        setShow(false);
    };

    const handleContinue = () => {
        setShow(false);
        doConfirmation();
    };

    return (
        <Modal show={show} onHide={handleDisregard}>
            <Modal.Body>
                <h3>Are you sure?</h3>

                {
                    messageText.map((msg: string, idx: any) =>
                        <p key={idx}>{msg}</p>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDisregard}>
                    {Strings.NOT_NOW_BUTTON}
                </Button>
                <Button variant="danger" onClick={handleContinue}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
