import {mapToObject} from "../../utils/objectUtils";
import {UserSetting} from "./userSetting";

export class UserSettingList extends Array<UserSetting> {

    static fromJson(json: any[]): UserSettingList {
        const obj = new UserSettingList();

        json.forEach(function (item: any) {
            obj.push(UserSetting.fromJson(item));
        });

        return obj;
    }

    /* Converts the settings list to a key/value dictionary.*/
    toDict(): any {
        const d = new Map();

        this.forEach(function (s: UserSetting) {
            d.set(s.settingKey, s.settingValue);
        });

        return mapToObject(d);
    }

    toJson(): any[] {
        const settingList: any[] = [];

        this.forEach(function (e: UserSetting) {
            settingList.push(e.toJson());
        });

        return settingList;
    }
}