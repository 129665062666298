import {mapToObject} from "../../utils/objectUtils";
import {ReportStatement} from "./reportStatement";
import {ReportStatementList} from "./reportStatementList";

/* Converts a statement list of a dictionary of entries for importing into a report. */
export function toEntriesDict(statementList: ReportStatementList) {

    const innerMap = new Map<string, number>();

    statementList.forEach(function (stmt: ReportStatement) {
        const entryList = stmt.entries;

        entryList.forEach(function (entry) {
            const accountKey = entry.accountKey;
            const amount = entry.amount;
            // entries.push({accountKey: amount});

            innerMap.set(accountKey, amount);
        });
    });

    const outerMap = new Map<string, Map<string, number>>();
    outerMap.set('entries', innerMap);

    return mapToObject(outerMap);
}