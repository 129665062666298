export class UserSetting {
    settingKey: string = '';
    settingValue: any | null = null;

    static fromJson(json: any) {
        const obj = new UserSetting();

        obj.settingKey = json.setting_key;
        obj.settingValue = json.setting_value;

        return obj;
    }

    toJson() {
        return {
            "setting_key": this.settingKey,
            "setting_value": this.settingValue
        };
    }
}