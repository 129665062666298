import {yupResolver} from "@hookform/resolvers/yup";
import * as React from "react";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import {ApiEndpoints} from "../../api/apiEndpoints";
import {Company} from "../../api/companies/company";
import {companyValidationSchema} from "../../api/companies/companyValidationSchema";
import {IndustryList} from "../../api/industries/industryList";
import {Country} from "../../api/misc/country";
import {CountryList} from "../../api/misc/countryList";
import {Currency} from "../../api/misc/currency";
import {CurrencyList} from "../../api/misc/currencyList";
import {useCompanyList} from "../../CompanyProvider";
import FormError from "../../components/FormError";
import FormSuccess from "../../components/FormSuccess";
import SubmitButton from "../../components/SubmitButton";
import useCompanySave from "../../hooks/useCompanySave";
import usePostData from "../../hooks/usePostData";
import {getFieldToFocus} from "../../utils/pageUtils";
import {PageEndpoints} from "../pageEndpoints";
import {PageStatus} from "../pageStatus";

const getMessage = (messages: string[]) => {
    let msg = '';

    messages.forEach(function (m: string) {
        if (m) {
            msg += ' ' + m;
        }
    });

    return msg;
};

const getStatus = (statuses: PageStatus[]) => {
    if (statuses.includes(PageStatus.IsReady)) {
        return PageStatus.IsReady;
    }

    if (statuses.includes(PageStatus.RequiresAuth)) {
        return PageStatus.RequiresAuth;
    }

    if (statuses.includes(PageStatus.HasError)) {
        return PageStatus.HasError;
    }

    if (statuses.includes(PageStatus.IsSubmitting)) {
        return PageStatus.IsSubmitting;
    }

    // All are finished.
    return PageStatus.HasSubmitted;
};

export default function CompanyEdit(
    {companyId}: {
        companyId: string
    }) {

    const navigate = useNavigate();
    const {getCompanies} = useCompanyList();

    const [industryList, setIndustryList] = useState<IndustryList | null>(null);
    const [currencyList, setCurrencyList] = useState<CurrencyList | null>(null);
    const [countryList, setCountryList] = useState<CountryList | null>(null);
    const [selectedSector, setSelectedSector] = React.useState('');

    const {
        status: industryStatus,
        message: industryMessage,
        submitForm: industrySubmit,
        results: industryResults
    } = usePostData();

    const {
        status: currencyStatus,
        message: currencyMessage,
        submitForm: currencySubmit,
        results: currencyResults
    } = usePostData();

    const {
        status: countryStatus,
        message: countryMessage,
        submitForm: countrySubmit,
        results: countryResults
    } = usePostData();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(companyValidationSchema),
        shouldFocusError: true
    });

    const utilityStatus = getStatus([industryStatus, currencyStatus, countryStatus]);

    const {status: saveStatus, message: saveMessage, validationErrors, saveCompany} = useCompanySave(companyId);

    // Fetch utility data.
    useEffect(() => {
        if (industryStatus === PageStatus.IsReady &&
            currencyStatus === PageStatus.IsReady &&
            countryStatus === PageStatus.IsReady) {
            industrySubmit(ApiEndpoints.industryList, {}, true, false);
            currencySubmit(ApiEndpoints.currenciesList, {}, true, false);
            countrySubmit(ApiEndpoints.countriesList, {}, true, false);
        }

    }, [industryStatus, industrySubmit, currencyStatus, currencySubmit, countryStatus, countrySubmit]);

    // Load utility data after fetching.
    useEffect(() => {
            if (industryStatus === PageStatus.HasSubmitted &&
                industryList === null &&
                currencyStatus === PageStatus.HasSubmitted &&
                currencyList === null &&
                countryStatus === PageStatus.HasSubmitted &&
                countryList === null) {

                setIndustryList(IndustryList.fromJson(industryResults));
                setCurrencyList(CurrencyList.fromJson(currencyResults));
                setCountryList(CountryList.fromJson(countryResults));
            }
        },
        [
            industryStatus, industryList, industryResults,
            currencyStatus, currencyList, currencyResults,
            countryStatus, countryList, countryResults
        ]);

    // After utilities list load, then load company.
    useEffect(() => {
        if (industryList && currencyList && countryList) {
            const existingCompanies = getCompanies();

            // After loading utility lists, load existing, selected company.
            existingCompanies.forEach(function (item: Company) {
                if (companyId === item.companyId) {
                    setSelectedSector(item.sectorKey);
                    reset(item.toJson());
                    return;
                }
            });
        }
    }, [industryList, currencyList, countryList, companyId, getCompanies, reset]);

    // If error when saving company, display validation message.
    useEffect(() => {
        if (saveStatus === PageStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, 'company_name');
            setFocus(fieldName);

            document.getElementById('companyRoot')?.scrollIntoView({behavior: 'smooth'});

        } else if (saveStatus === PageStatus.IsReady) {
            setFocus('company_name');
        }
    }, [saveStatus, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (utilityStatus === PageStatus.RequiresAuth ||
            saveStatus === PageStatus.RequiresAuth) {
            navigate(PageEndpoints.userSignOut);
        }
    }, [utilityStatus, saveStatus, navigate]);

    // New company was saved. Make sure header reloads and URL changes.
    useEffect(() => {
        if (saveStatus === PageStatus.HasSubmitted && companyId === '') {
            navigate(PageEndpoints.company);
        }

    }, [saveStatus, companyId, navigate]);

    const message = getMessage([industryMessage, currencyMessage, countryMessage, saveMessage]);

    const isBusy: boolean =
        utilityStatus === PageStatus.IsReady ||
        utilityStatus === PageStatus.IsSubmitting ||
        saveStatus === PageStatus.IsSubmitting;

    let sectorKeys: string[] = [];
    let subsectorKeys = null;

    if (industryList) {
        sectorKeys = industryList.getSectorKeys();

        if (selectedSector !== '') {
            subsectorKeys = industryList.getSubsectorKeys(selectedSector);
        }
    }

    const isFormReady = isDirty && isValid;
    const submitText = companyId === '' ? 'Add company' : 'Update company';

    const onSubmitHandler = async (data: any) => {
        saveCompany(data);
    };

    const onCancelClick = (e: any) => {
        e.preventDefault();
        navigate(PageEndpoints.home);
    };

    const handleSectorChange = (event: any) => {
        setSelectedSector(event.target.value);
    };

    return (
        <div className="row">
            <div className="col">
                <FormError status={utilityStatus} message={message}/>
                <FormError status={saveStatus} message={message}/>
                <FormSuccess status={saveStatus} message={message}/>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <fieldset id="me" disabled={isBusy}>
                        <input {...register("company_id", {value: ""})} type="hidden"/>
                        <div>
                            <label
                                htmlFor="companyName"
                                className="form-label">
                                Company name
                            </label>
                            <input
                                {...register('company_name')}
                                type="text" id="companyName"
                                className="form-control"
                                placeholder="Example Inc."/>
                            <small className="text-danger">
                                {errors?.company_name?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label
                                htmlFor="sectorKey"
                                className="form-label">
                                Sector
                            </label>
                            <select
                                {...register('sector_key')}
                                id="sectorKey"
                                className="form-select"
                                defaultValue=""
                                onChange={handleSectorChange}>
                                <option key="" value="">Select sector...</option>
                                {
                                    sectorKeys.map(key => (
                                        <option key={key} value={key}>
                                            {industryList && industryList.getSectorNameByKey(key)}
                                        </option>
                                    ))
                                }
                            </select>
                            <small className="text-danger">
                                {errors?.sector_key?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="subsectorKey" className="form-label">
                                Subsector
                            </label>
                            <select
                                {...register('subsector_key')}
                                id="subsectorKey"
                                className="form-select"
                                defaultValue=""
                                disabled={subsectorKeys === null}>
                                <option key="" value="">Select subsector...</option>
                                {
                                    subsectorKeys ? subsectorKeys.map((key: any) =>
                                        <option value={key} key={key}>
                                            {industryList && industryList.getSectorNameByKey(key)}
                                        </option>
                                    ) : <></>
                                }
                            </select>
                            <small className="text-danger">
                                {errors?.subsector_key?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="companyCurrency" className="form-label">Currency</label>
                            <input
                                {...register('currency_code')}
                                className="form-control"
                                list="companyCurrencyOptions"
                                id="companyCurrency"
                                placeholder="Type to search..."/>
                            <datalist id="companyCurrencyOptions">
                                {
                                    currencyList && currencyList.map((e: Currency) =>
                                        <option
                                            value={e.currencyCode}
                                            key={e.currencyCode}>{e.currencyName}
                                        </option>)
                                }
                            </datalist>
                            <small className="text-danger">
                                {errors?.currency_code?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="companyCountry" className="form-label">Country code</label>
                            <input
                                {...register('country_code')}
                                className="form-control"
                                list="companyCountryOptions"
                                id="companyCountry"
                                placeholder="Type to search..."/>
                            <datalist id="companyCountryOptions">
                                {
                                    countryList && countryList.map((e: Country) =>
                                        <option
                                            value={e.countryCode}
                                            key={e.countryCode}>{e.countryName}
                                        </option>)
                                }
                            </datalist>
                            <small className="text-danger">
                                {errors?.country_code?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="websiteUrl" className="form-label">Website</label>
                            <input
                                {...register('website_url')}
                                type="text"
                                id="websiteUrl"
                                className="form-control"
                                placeholder="https://www.example.com"/>
                            <small className="text-danger">
                                {errors?.website_url?.message?.toString()}
                            </small>
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div>
                            <SubmitButton
                                isFormReady={isFormReady}
                                featureStatus={saveStatus}
                                text={submitText}
                            />
                            {
                                companyId === '' &&
                                (
                                    <button className="btn btn-lg btn-outline-secondary"
                                            onClick={onCancelClick}>
                                        Cancel
                                    </button>
                                )
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}