import {Account} from "./account";

export class AccountList extends Array<Account> {

    static fromJson(json: any[]): AccountList {
        const obj = new AccountList();

        json.forEach(function (item: any) {
            obj.push(Account.fromJson(item));
        });

        return obj;
    }
}