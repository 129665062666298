import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {Company} from "../api/companies/company";
import {postData} from "../api/postData";
import {ReportList} from "../api/reports/reportList";
import {getHeaders} from "../api/requestHeaders";
import {useAuth} from "../AuthProvider";
import {useCompanyList} from "../CompanyProvider";
import {PageStatus} from "../pages/pageStatus";

export default function useCompanyReport(
    companyId: string) {
    const {getToken} = useAuth();
    const {getCompanies} = useCompanyList();

    const [status, setStatus] = useState(PageStatus.IsLoading);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    const [company, setCompany] = useState<Company | null>(null);
    const [reportList, setReportList] = useState<ReportList>(new ReportList());

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async () => {
            if (status !== PageStatus.IsLoading) {
                return;
            }

            const companyList = getCompanies();
            const co = companyList.getCompany(companyId);
            setCompany(co);

            if (co === null) {
                setStatus(PageStatus.IsMissingCompany);
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const data = {
                'company_id': co.companyId
            };

            const reportResults = await postData(ApiEndpoints.companyReportsList, data, headers, signal);

            if (doExitEarly(reportResults)) {
                return;
            }

            const reportListResults = reportResults.results;
            const reports = ReportList.fromJson(reportListResults);

            setReportList(reports);

            if (reports.length === 0) {
                setStatus(PageStatus.IsMissingReport);
                return;
            }

            setStatus(PageStatus.IsLoaded);
        };

        postForm().then();

        return () => {
            controller.abort();
        };

    }, [status, getCompanies, companyId, getToken]);

    const resetState = () => {
        setStatus(PageStatus.IsLoading);
        setMessage('');
        setValidationErrors([]);

        setCompany(null);
        setReportList(new ReportList());
    };

    return {
        status, message, validationErrors, company, reportList, resetState
    };
}