import {AccountingTemplateEntry} from "./accountingTemplateEntry";

export class AccountingTemplateEntryList extends Array<AccountingTemplateEntry> {

    static fromJson(json: any[]): AccountingTemplateEntryList {
        const obj = new AccountingTemplateEntryList();

        json.forEach(function (item: any) {
            obj.push(AccountingTemplateEntry.fromJson(item));
        });

        return obj;
    }
}